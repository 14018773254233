var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex flex-column align-items-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center my-20" },
        [
          _c(
            "v-avatar",
            { attrs: { size: "96" } },
            [
              _c("v-img", {
                attrs: {
                  "lazy-src": require("@/assets/images/placeholder.png"),
                  src:
                    _vm.Profile.pic != null && _vm.Profile.pic !== ""
                      ? _vm.Profile.pic
                      : require("@/assets/images/profile.jpg"),
                },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "f14 font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.Profile.firstname + " " + _vm.Profile.lastname) +
                " "
            ),
          ]),
          _c("span", { staticClass: "my-1 f13 left-to-right" }, [
            _vm._v(" " + _vm._s(_vm.Profile.mobile) + " "),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "w-100 white rounded-t-xl" },
        [
          _c("div", { staticClass: "sheet-handle w-100 text-center" }, [
            _c("span"),
            _c("span"),
          ]),
          _c(
            "v-list",
            {
              staticClass: "w-100 pt-0 mt-auto",
              attrs: { light: "", shaped: "" },
            },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/s/profile/edit",
                        href: "/s/profile/edit",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#e8ffff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "profile",
                                  color: "#00d0d4",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Edit profile")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/s/profile/edit/call",
                        href: "/s/profile/edit/call",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#EDE7F6",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "call",
                                  color: "#512DA8",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Call information")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.Profile.clinic &&
                  _vm.Profile.clinic.ownerId === _vm.Profile.id
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            ripple: "",
                            to: `/s/clinic/${_vm.Profile.clinic.id}/owner`,
                            href: `/s/clinic/${_vm.Profile.clinic.id}/owner`,
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-sheet",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded-lg",
                                  attrs: {
                                    color: "#F9FBE7",
                                    elevation: "0",
                                    height: "36",
                                    width: "36",
                                  },
                                },
                                [
                                  _c("iconly", {
                                    attrs: {
                                      type: "bulk",
                                      name: "work",
                                      color: "#AFB42B",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "font-weight-bold f15" },
                                [_vm._v(_vm._s(_vm.$t("My clinic")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "arrow-left2",
                                  color: "#757575",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            ripple: "",
                            to: `/s/clinic/${_vm.Profile.clinic.id}/member`,
                            href: `/s/clinic/${_vm.Profile.clinic.id}/member`,
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c(
                                "v-sheet",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center rounded-lg",
                                  attrs: {
                                    color: "#F9FBE7",
                                    elevation: "0",
                                    height: "36",
                                    width: "36",
                                  },
                                },
                                [
                                  _c("iconly", {
                                    attrs: {
                                      type: "bulk",
                                      name: "work",
                                      color: "#AFB42B",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "font-weight-bold f15" },
                                [_vm._v(_vm._s(_vm.$t("Clinic")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "arrow-left2",
                                  color: "#757575",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/s/financial",
                        href: "/s/financial",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#e4ecff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "wallet",
                                  color: "#5081f5",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Financial")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/s/profile/favorites",
                        href: "/s/profile/favorites",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#fff9e4",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "bookmark",
                                  color: "#F9A825",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Favorites")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        href: "https://mindology.io/about-us",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#E8EAF6",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "infocircle",
                                  color: "#5C6BC0",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("About us")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        href: "https://mindology.io/contact-us",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#F1F8E9",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "bulk",
                                  name: "calling",
                                  color: "#689F38",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Contact us")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2",
                          attrs: {
                            color: "accent",
                            block: "",
                            rounded: "",
                            large: "",
                          },
                          on: { click: _vm.logout },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Log out")) + " "),
                          _c("iconly", {
                            staticClass: "ms-2",
                            attrs: {
                              type: "bulk",
                              name: "logout",
                              color: "#FFFFFF",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-center d-block w-100 fw-bold f14 grey--text text--darken-3",
                    },
                    [_vm._v("v" + _vm._s(_vm.version))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }