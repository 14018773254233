<template>
  <section class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column align-items-center my-20">
      <v-avatar size="96">
        <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="
          Profile.pic != null && Profile.pic !== ''
            ? Profile.pic
            : require('@/assets/images/profile.jpg')
        "></v-img>
      </v-avatar>
      <span class="f14 font-weight-bold ">
        {{ Profile.firstname + " " + Profile.lastname }}
      </span>

      <span class="my-1 f13 left-to-right">
        {{ Profile.mobile }}
      </span>
    </div>
    <v-card class="w-100 white rounded-t-xl">
      <div class="sheet-handle w-100 text-center">
        <span></span>
        <span></span>
      </div>
      <v-list class="w-100 pt-0 mt-auto" light shaped>
        <v-list-item-group color="primary">
          <v-list-item link ripple to="/s/profile/edit" href="/s/profile/edit">
            <v-list-item-icon>
              <v-sheet color="#e8ffff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="profile" color="#00d0d4" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Edit profile') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/s/profile/edit/call" href="/s/profile/edit/call">
            <v-list-item-icon>
              <v-sheet color="#EDE7F6" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="call" color="#512DA8" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Call information')}}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple
                       :to="`/s/clinic/${Profile.clinic.id}/owner`" :href="`/s/clinic/${Profile.clinic.id}/owner`"
                       v-if="Profile.clinic && Profile.clinic.ownerId === Profile.id">
            <v-list-item-icon>
              <v-sheet color="#F9FBE7" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="work" color="#AFB42B" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('My clinic') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple
                       :to="`/s/clinic/${Profile.clinic.id}/member`"
                       :href="`/s/clinic/${Profile.clinic.id}/member`" v-else >
            <v-list-item-icon>
              <v-sheet color="#F9FBE7" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="work" color="#AFB42B" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Clinic') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/s/financial" href="/s/financial">
            <v-list-item-icon>
              <v-sheet color="#e4ecff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="wallet" color="#5081f5" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Financial') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/s/profile/favorites" href="/s/profile/favorites">
            <v-list-item-icon>
              <v-sheet color="#fff9e4" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="bulk" name="bookmark" color="#F9A825" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Favorites') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>


          <v-divider></v-divider>

          <v-list-item link ripple href="https://mindology.io/about-us" target="_blank">
            <v-list-item-icon>
              <!--  -->
              <v-sheet color="#E8EAF6" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <!-- #5C6BC0 -->
                <iconly type="bulk" name="infocircle" color="#5C6BC0" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('About us') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link ripple href="https://mindology.io/contact-us" target="_blank">
            <v-list-item-icon>
              <v-sheet color="#F1F8E9" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <!-- #689F38 -->
                <iconly type="bulk" name="calling" color="#689F38" />
              </v-sheet>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Contact us') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item>
            <v-btn color="accent" block rounded large class="my-2" @click="logout">
              {{ $t('Log out') }}
              <iconly type="bulk" name="logout" color="#FFFFFF" class="ms-2" />
            </v-btn>
          </v-list-item>
          
          <span class="text-center d-block w-100 fw-bold f14 grey--text text--darken-3">v{{version}}</span>

        </v-list-item-group>
      </v-list>
    </v-card>
  </section>
</template>
<script>
import apiService from "@/http/ApiService.js";
import {version} from '@/../package.json';

export default {
  data() {
    return {
      version,
      
      Profile: {
        clinic: {
          id: -1,
        },
        firstname: "",
        lastname: "",
      },
    };
  },
  methods: {
    logout() {
      this.$swal({
        icon: "warning",
        showDenyButton: true,
        text: this.$t('Do you want to log out?'),
        confirmButtonText: this.$t('Yes'),
        denyButtonText: this.$t('No'),
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("logout");
            this.$router.push("/auth");
          }
        });
    },
    getDoctorInformation(id) {
      apiService
        .getDoctorInfoById(id)
        .then((DATA) => {
          this.Profile = DATA.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.getDoctorInformation(this.$store.getters.doctorId);
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.$vuetify.theme.dark = false;
  }
};
</script>